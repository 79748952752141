<template>
  <div class="container">
    <van-search
      v-model="address"
      show-action
      :placeholder="$t('warning.searchAddress')"
      @search="onSearch"
    >
      <template #action>
        <div @click="onSearch">{{ $t("common.search") }}</div>
      </template>
    </van-search>
    <div class="main">
      <mescroll-vue
        class="mescroll"
        id="container"
        ref="mescroll"
        :down="mescrollDown"
        :up="mescrollUp"
        @init="mescrollInit"
      >
        <div class="mescroll-view">
          <div
            class="mescroll-item"
            v-for="(item, index) in dataList"
            :key="index"
          >
            <div class="address">{{ item.address }}</div>
            <div class="info">
              <div class="info-item">
                {{ $t("manage.privatePlacementQuantity") }}：{{ item.simumcc }}
              </div>
              <div class="info-item">{{ $t("manage.pledgeQuantity") }}：{{ item.zhiyamcc }}</div>
            </div>
          </div>
        </div>
      </mescroll-vue>
    </div>
  </div>
</template>

<script>
import MescrollVue from "mescroll.js/mescroll.vue";
import { getUSerPladgeList } from "@/request/api";
import { walletAddressHide } from "@/common/utils";
export default {
  components: { MescrollVue },
  data() {
    return {
      mescroll: null,
      mescrollDown: {
        textInOffset: this.$t("mescroll.textInOffset"),
        textOutOffset: this.$t("mescroll.textOutOffset"),
        textLoading: this.$t("mescroll.textLoading"),
      },
      mescrollUp: {
        callback: this.upCallback,
        empty: {
          tip: this.$t("mescroll.empty"),
          warpId: "container",
          icon: "/images/mescroll-empty.png",
        },
        htmlLoading: `<p class="upwarp-progress mescroll-rotate"></p><p class="upwarp-tip">${this.$t(
          "mescroll.loading"
        )}</p>`,
        htmlNodata: `<p class="upwarp-nodata">-- ${this.$t(
          "mescroll.end"
        )} --</p>`,
      },
      dataList: [], // 列表数据
      number: "",

      address: "",
    };
  },
  mounted() {},
  methods: {
    mescrollInit(mescroll) {
      this.mescroll = mescroll;
    },
    upCallback(page, mescroll) {
      getUSerPladgeList({
        page: page.num,
        limit: page.size,
        address: this.address,
      }).then((res) => {
        if (res.code == 200) {
          // 请求的列表数据
          let arr = res.data.list;
          // 如果是第一页需手动置空列表
          if (page.num === 1) this.dataList = [];
          // 把请求到的数据添加到列表
          this.dataList = this.dataList.concat(arr);
          // 数据渲染成功后,隐藏下拉刷新的状态
          this.$nextTick(() => {
            mescroll.endBySize(arr.length, res.data.total);
          });
        } else {
          this.$toast.fail(res.msg);
          mescroll.endErr();
        }
      });
    },
    onSearch() {
      this.mescroll.triggerDownScroll();
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  overflow: hidden;
  background-color: #fff !important;
  display: flex;
  flex-direction: column;
  .van-search {
    padding: 0;
    margin-bottom: 20px;
  }
  .main {
    flex: 1;
    overflow: hidden;
  }
  .mescroll {
    .mescroll-view {
      .mescroll-item {
        padding: 26px 34px;
        margin-bottom: 30px;
        background-color: rgba(255, 215, 156, 0.2);
        border-radius: 20px;
        .address {
          font-size: 26px;
          font-weight: 500;
          color: #333333;
          margin-bottom: 10px;
        }
        .info {
          .info-item {
            margin-bottom: 5px;
            font-size: 24px;
          }
        }
      }
    }
  }
}
</style>
